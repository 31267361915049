<script setup lang="ts">
const switchLocalePath = useSwitchLocalePath();
const { locale } = useI18n();
const { push } = useRouter();

const languages = [
  {
    locale: "pt-BR",
    image: "/images/flags/BR.svg",
    name: "Português",
  },
  {
    locale: "it",
    image: "/images/flags/IT.svg",
    name: "Italiano",
  },
  {
    locale: "en",
    image: "/images/flags/US.svg",
    name: "English",
  },
  {
    locale: "zh-CN",
    image: "/images/flags/CN.svg",
    name: "中文",
  },
];

const switchLanguage = (lang: string) => {
  push(switchLocalePath(lang));
};

const currentLanguage = computed(() => {
  return languages.find((lang) => lang.locale === locale.value);
});
</script>
<template>
  <Select
    @update:modelValue="switchLanguage"
    :modelValue="currentLanguage?.locale"
  >
    <SelectTrigger class="w-20 bg-white/5 border-none">
      <SelectValue />
    </SelectTrigger>
    <SelectContent>
      <SelectGroup>
        <SelectItem v-for="language in languages" :value="language.locale">
          <img :src="language.image" :alt="language.name" class="h-5" />
        </SelectItem>
      </SelectGroup>
    </SelectContent>
  </Select>
</template>
